import React, { Component } from 'react'
import { Button, CircularProgress, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { getSecurityQuestions } from '../../api/Defaults';


export default class SecurityQuestionForms extends Component {
    securityQuestions: any;
    state: any;
    props: any;

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            questions: []
        }

        getSecurityQuestions().then((res: any) => {
            this.securityQuestions = res.data
            this.setState({
                questions: [
                    this.getSecurityQuestionForm(0),
                    this.getSecurityQuestionForm(1),
                    this.getSecurityQuestionForm(2)
                ],
                isLoading: false
            })
        })
    }

    randomise = (index: number) => {
        let questions = this.state.questions
        questions[index] = this.getSecurityQuestionForm(index)
        this.setState({ questions: questions })
    }

    getSecurityQuestionForm = (index: number) => {
        let question = this.securityQuestions[Math.floor(Math.random() * this.securityQuestions.length)]
        if (this.securityQuestions)
            return (
                <FormGroup row key={question.id} sx={{ my: 2 }}>
                    <Typography>{question.question}</Typography>
                    <TextField
                        name={'question-' + index}
                        required
                        sx={{ width: 0.84, m: 0 }}
                        id={'question-' + index}
                        label={question.question}
                    />
                    <Button variant="contained" sx={{ width: 0.16, m: 0 }} onClick={() => this.randomise(index)}>
                        <Refresh></Refresh>
                    </Button>
                </FormGroup>
            )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Grid container justifyContent="center">
                    <CircularProgress></CircularProgress>
                </Grid>
            )
        }
        else {
            return (
                <React.Fragment>
                    {this.state.questions}
                </React.Fragment >
            )
        }
    }
}

