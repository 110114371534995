import React, { Component } from 'react'
import { Redirect } from '../app/App';

export default class Dashboard extends Component {
  props: any

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Redirect state={this.props.state}>
        <div>Dashboard</div>
      </Redirect>
    )
  }
}
