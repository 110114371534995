import axios from 'axios'
import { get_env } from '../components/app/App';


export class Api {
    axiosInstance: any;

    constructor() {
        this.axiosInstance = axios.create({ baseURL: get_env().fastAPIURL });
    }

    async post(endpoint: string, data: any, includeTimeZone: boolean = false) {
        if (typeof (data) == 'string') data = { data: data }
        if (includeTimeZone) {
            data.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        return this.axiosInstance.post(endpoint, data)
    }

    async get(endpoint: string) {
        return this.axiosInstance.get(endpoint)
    }
}
