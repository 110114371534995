import React, { Component } from 'react'
import { LoginParticles } from './LoginParticles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { SignIn } from './SignIn';
import { Button, Container, Paper } from '@mui/material';
import { get_env } from '../app/App';
import SignUp from './SignUp';
import { login } from '../../api/Users';

export default class Login extends Component {

    showSignUp: Boolean = false
    errors: string | any[]
    securityQuestions: any
    details: { username: string | null; password: string | null; otp: string | null }
    form: any
    settings: { isLoggedIn: boolean; reCaptchaKey: string; fastAPIURL: string; } | any
    usernameRef: React.RefObject<any>
    formRef: React.RefObject<any>
    props: any
    state: any
    usernameAvailableRef: React.RefObject<typeof Button>

    constructor(props: any) {
        super(props);
        this.state = {
            grid: <SignIn props={this} />,
            showSignUp: true
        }
        this.errors = []
        this.details = { username: null, password: null, otp: null }
        this.settings = get_env()

        this.usernameRef = React.createRef()
        this.usernameAvailableRef = React.createRef()
        this.formRef = React.createRef()
    }

    toggleShowSignUp = () => {
        this.setState({ showSignUp: !this.state.showSignUp })
        let grid = this.state.showSignUp ? <SignUp props={this} /> : <SignIn props={this} />
        this.setState({ grid: grid })
    }

    handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.errors = this.handleSignInValidation(data)
        this.setState({ grid: <SignIn props={this} /> })
        if (!this.errors.length) {
            let details = {
                username: String(data.get('username')),
                password: String(data.get('password')),
                otp: String(data.get('otp'))
            }
            login(details).then((res) => {
                console.log(res.data)
            })
        }
    }

    handleSignInValidation = (data: FormData) => {
        let username = String(data.get('username')) || ''
        let password = String(data.get('password')) || ''
        let otp = String(data.get('otp')) || ''

        let errors: String[] = []

        if (!username) errors.push('Username is required!')
        else if (!username.match(/^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/)) {
            errors.push('Username must only feature A-Z, 0-9, _ and .', 'Username must not begin/end with _ or .', 'Username must not feature __ or ..')
        }
        else if (['.', '_'].includes(username.substring(username.length - 1))) {
            errors.push('Username must not begin/end with _ or .')
        }

        if (!password) errors.push('Password is required!')

        if (!otp) errors.push('2FA is required!')
        else if (!otp.match(/^(\d{6})$/)) errors.push('2FA must only feature 6 digits, e.g: 269483')

        return errors
    }

    signInUpButton() {
        let text = this.state.showSignUp ? "Already have an account? Sign in" : "Don't have an account? Sign Up"
        return (
            <Container maxWidth="sm">
                <Button size="small" sx={{ mb: 10 }} onClick={this.toggleShowSignUp}>{text}</Button>
            </Container>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid item xs={false} sm={2} md={5} lg={8}>
                        <LoginParticles></LoginParticles>
                    </Grid>
                    <Grid zIndex={1} item xs={12} sm={10} md={7} lg={4} component={Paper} elevation={6} square>
                        {this.state.grid}
                        {this.signInUpButton()}
                    </Grid>
                </Grid>
            </React.Fragment>

        )
    }
}
