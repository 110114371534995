import { Alert, Avatar, Box, Button, Container, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export function SignIn(props: any) {
    props = props.props
    let errors = [];
    if (props.errors) {
        for (let index in props.errors) {
            errors.push(<Alert key={'err-' + index} sx={{ my: 1 }} severity='error'>{props.errors[index]}</Alert>)
        }
    }
    else {
        errors = []
    }

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    my: 2,
                    mx: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={props.handleSignIn} onReset={props.toggleShowSignUp}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="otp"
                        label="2FA Password"
                        type="password"
                        id="otp"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    {errors}
                    <Button size='small'>
                        Forgot password?
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}