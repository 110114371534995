import { Component } from 'react'
import { Box, Button, ButtonGroup, CircularProgress, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { generateTOTP } from '../../api/Users';
import { QRCodeCanvas } from 'qrcode.react';
import { AddToHomeScreen, ContentCopy } from '@mui/icons-material';
import { get_env } from '../app/App';


export default class OTPForm extends Component {
    token_link: string
    url: string;
    username: string;
    state: any;
    props: any;
    token: string;
    tokenId: string;
    isLoading: boolean;

    constructor(props: any) {
        super(props);
        this.state = { isLoading: true }

        this.isLoading = true
        this.token = ''
        this.tokenId = ''
        this.token_link = ''
        this.url = ''
        this.username = 'Galloway' // TODO: Make it so on clicking 'username available, it locks in the username here'

        generateTOTP().then((res: any) => {
            this.url = get_env().URL
            this.token = res.data.totp
            this.tokenId = res.data.id
            this.token_link = 'otpauth://totp/' + this.username + ':' + this.username + '%40' + this.url + '?secret=' + res.data.otp + '&issuer=' + this.url
            this.setState({ isLoading: false })
        })
    }

    render() {
        return (
            <FormGroup row key={this.tokenId} sx={{ my: 2 }}>
                <Typography>Use an authenticator mobile app (like Google Authenticator, or iOS Passwords) to scan the QR Code, or click copy if on mobile.</Typography>

                {this.state.isLoading ?
                    <Grid container justifyContent="center">
                        <Typography sx={{ mx: 6 }}>(Requires Username & Email to be filled out)</Typography>
                        <CircularProgress></CircularProgress>
                    </Grid>
                    :
                    <Grid container justifyContent="center">
                        <ButtonGroup>
                            <Button sx={{ mr: 1 }} variant="contained" onClick={() => { navigator.clipboard.writeText(this.token) }}>Copy Token<ContentCopy sx={{ ml: 1 }}></ContentCopy></Button>
                            <Button variant="contained" sx={{}} href={this.token_link}>Open Authenticator<AddToHomeScreen sx={{ ml: 1 }}></AddToHomeScreen></Button>
                        </ButtonGroup>
                        <Box sx={{ mt: 2, mx: 3, p: 0.5, pb: 0 }} bgcolor={'whitesmoke'}>
                            <QRCodeCanvas value={this.token_link}></QRCodeCanvas>
                        </Box>
                    </Grid>
                }
                <TextField required fullWidth margin="normal" label="OTP" name="otp" />
            </FormGroup>
        )
    }
}

