import { ThemeProvider } from '@emotion/react'
import { Typography, createTheme } from '@mui/material';
import { Component, useState } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard';
import Login from '../login/Login';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Fragment } from 'react-is';


export default class App extends Component {

    theme = createTheme({ palette: { mode: 'dark' } })
    env = get_env()
    state = {
        isLoggedIn: false
    }

    render() {
        return (
            <GoogleReCaptchaProvider reCaptchaKey='6Le0mhklAAAAAMwrMMFUo4rZ4zlINodCPLYEd0-1'>
                <ThemeProvider theme={this.theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login state={this.state} setState={this.setState} />}></Route>
                            <Route path="/dashboard" element={<Dashboard state={this.state} setState={this.setState} />} />
                            <Route path="/" element={<Redirect state={this.state} />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </GoogleReCaptchaProvider>
        )
    }
}

export function Redirect(props: any) {
    function redirect() {
        return props.state.isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
    }

    return (
        <div>
            {redirect()}
        </div>

    )
}

export function get_env() {
    return {
        isLoggedIn: false,
        reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
        fastAPIURL: process.env.REACT_APP_FAST_API_URL || '',
        URL: process.env.REACT_APP_URL || '',
    }
}