import { Api } from "./Api";

const api = new Api()

export function verifyRecaptcha(token: string) {
    return api.post('users/verify_recaptcha', { token: token })
}

export function generateTOTP() {
    return api.get('users/generate_totp')
}

export function isUsernameAvailable(username: string) {
    return api.post('users/username_available', { username: username })
}

export function login(details: { username: string | null; password: string | null; otp: string | null; }) {
    return api.post('users/login', details)
}

export function signUp(details: any) {
    return api.post('users/sign_up', details)
}