import { Alert, Avatar, Box, Button, Container, FormGroup, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityQuestionForms from './SecurityQuestions';
import OTPForm from './OTP';
import { FormEvent, createRef, useState } from 'react';
import { isUsernameAvailable, signUp, verifyRecaptcha } from '../../api/Users';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';


export default function SignUp(props: any) {
    const [availableColor, setUserAvailableColor] = useState("primary");
    const [availableText, setUserAvailableText] = useState('Available?');

    const [reCaptchaTries, setRecaptchaTries] = useState(0)
    const [reCaptchaToken, setRecaptchaToken] = useState('')
    const [reCaptchaSuccess, setRecaptchaSuccess] = useState(false)

    const [errors, setErrors] = useState(props.errors)

    const securityQuestionsRef = createRef()
    const OTPFormRef = createRef()

    if (props.errors) {
        let errs: any[] = []
        for (let index in props.errors) {
            errs.push(<Alert key={'err-' + index} sx={{ my: 1 }} severity='error'>{props.errors[index]}</Alert>)
        }
        let concatErrors: any[] = errs.concat(errors, errors)
        setErrors(concatErrors)
    }

    function checkUsername(event: any) {
        let node = new FormData(event.target.form)
        const username = String(node.get('username'))
        if (username.length) {
            isUsernameAvailable(username).then((res) => {
                let data = JSON.parse(res.data)
                if (data === true) {
                    updateUserAvailable('success', 'Available!')
                }
                else {
                    updateUserAvailable('error', 'Taken!')
                }
            })
        }
    }

    function handleSignUp(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        let newErrors = []
        const data = new FormData(event.currentTarget);

        let questionIds = getAskedSecurityQuestions()
        let tokenId = getOTPTokenId()



        // Try one more recaptcha if we've come up with failures
        if (!reCaptchaSuccess) {
            setRecaptchaTries(reCaptchaTries - 1)
            onVerifyRecaptcha(reCaptchaToken)
            if (!reCaptchaSuccess) {
                newErrors.push("Bot check failed, you robot, you!")
            }
        }

        if (String(data.get('passwordConfirm')) !== String(data.get('password'))) {
            newErrors.push("Password and Confirmation aren't the same")
        }

        let details = {
            username: String(data.get('username')),
            email: String(data.get('email')),
            firstName: String(data.get('firstName')),
            lastName: String(data.get('lastName')),
            password: String(data.get('password')),
            question0Answer: String(data.get('question-0')),
            question1Answer: String(data.get('question-1')),
            question2Answer: String(data.get('question-2')),
            question0Id: questionIds[0],
            question1Id: questionIds[1],
            question2Id: questionIds[2],
            otp: String(data.get('otp')),
            otpId: tokenId,
            reCaptchaToken: reCaptchaToken

        }
        console.log(details)

        signUp(details).then((res) => {
            console.log(res)
        })

    }

    function getAskedSecurityQuestions() {
        let current: any = securityQuestionsRef.current
        let questionElems: any = current.state.questions

        return [parseInt(questionElems[0].key),
        parseInt(questionElems[1].key),
        parseInt(questionElems[2].key)
        ]

    }

    function getOTPTokenId() {
        let current: any = OTPFormRef.current
        return current.tokenId
    }

    function onVerifyRecaptcha(token: string) {
        if (!reCaptchaSuccess && reCaptchaTries < 5) {
            verifyRecaptcha(token).then((res: any) => {
                if (res.data === true) {
                    setRecaptchaSuccess(true)
                    setRecaptchaToken(token)
                }
                else {
                    setRecaptchaTries(reCaptchaTries + 1)
                }
            })
        }
    }

    function handleUserAvailableChange() {
        if (availableColor !== 'priamry') {
            updateUserAvailable('primary', 'Available?')
        }
    }

    function updateUserAvailable(color: string, text: string) {
        setUserAvailableColor(color)
        setUserAvailableText(text)
    }

    function getColor(availableColor: string): import("@mui/types").OverridableStringUnion<"primary" | "inherit" | "error" | "success" | "info" | "warning" | "secondary", import("@mui/material").ButtonPropsColorOverrides> | undefined {
        if (availableColor === 'primary') return 'primary'
        if (availableColor === 'success') return 'success'
        if (availableColor === 'error') return 'error'
    }

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    my: 2,
                    mx: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" ref={props.formRef} noValidate sx={{ mt: 1 }} onReset={props.toggleShowSignUp} onSubmit={handleSignUp}>
                    <FormGroup row>
                        <TextField
                            onChange={handleUserAvailableChange}
                            ref={props.usernameRef}
                            required
                            margin="normal"
                            sx={{ width: 0.75 }}
                            label="Username"
                            id="username"
                            name="username"
                            autoFocus />
                        <Button
                            variant="contained"
                            ref={props.usernameAvailableRef}
                            sx={{ width: 0.25, mt: 2, mb: 1 }}
                            onClick={(e) => checkUsername(e)}
                            color={getColor(availableColor)}>
                            {availableText}
                        </Button>
                    </FormGroup>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        margin="normal"
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordConfirm"
                        label="Confirm Password"
                        type="password"
                        id="passwordConfirm"
                        autoComplete="new-password"
                    />
                    <SecurityQuestionForms ref={securityQuestionsRef}></SecurityQuestionForms>
                    <OTPForm ref={OTPFormRef}></OTPForm>
                    <GoogleReCaptcha onVerify={(e) => onVerifyRecaptcha(e)} />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                    >Sign Up</Button>
                </Box>
            </Box>
        </Container>
    )
}
